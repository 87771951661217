import { Button, Dialog, Divider, Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import left from '../Assets/left.svg';
import right from '../Assets/right.svg';
import center from '../Assets/center.svg';
import benifits from '../Assets/benifits.svg';
import divider from '../Assets/divider.svg';
import employeeFeedback from '../Assets/audios/employeeFeedback.wav';
import mentalWellness from '../Assets/audios/mentalWellness.wav';
import managementFeedback from '../Assets/audios/managementFeedback.wav';
import exitInterview from '../Assets/audios/exitInterview.wav';
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import GetInTouchModal from './Components/GetInTouchModal';
import SuccessModal from './Components/SuccessModal';
import BrandsCarousel from './Components/BrandsCarousel';

const VIDEO_LINK = 'https://www.youtube.com/watch?v=36oPqYZkmZ8&feature=youtu.be';

export default function Homescreen() {
	const small = useMediaQuery('(max-width:600px)');
	const [samples, setSamples] = useState([
		{
			title: 'Employee Feedback Conversation',
			audio: employeeFeedback,
		},
		{
			title: 'Employee Mental Wellness Conversation',
			audio: mentalWellness,
		},
		{
			title: 'Management Feedback Conversation',
			audio: managementFeedback,
		},
		{
			title: 'Stay / Exit Interview',
			audio: exitInterview,
		},
	]);
	const [others, setOthers] = useState([
		{
			title: 'Workplace Accommodation',
		},
		{
			title: 'Work Life Balance',
		},
		{
			title: 'Workplace Accommodation',
		},
		{
			title: 'Workplace Culture',
		},
		{
			title: 'Career Development',
		},
		{
			title: 'Compensation Review',
		},
		{
			title: 'Disciplinary',
		},
		{
			title: 'Harassment & Discrimination',
		},
	]);

	const [touchModal, setTouchModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);

	return (
		<Stack>
			<Dialog open={touchModal} onClose={() => setTouchModal(false)}>
				<GetInTouchModal onClose={() => setTouchModal(false)} setSuccessModal={setSuccessModal} />
			</Dialog>

			<Dialog open={successModal} onClose={() => setSuccessModal(false)}>
				<SuccessModal onClose={() => setSuccessModal(false)} />
			</Dialog>

			<Stack sx={{ background: 'linear-gradient(to bottom, #2F1A6E, #774F8F)' }} width="100%" height={540} bgcolor="red">
				<Stack sx={{ '& .MuiTypography-root': { color: '#fff' } }} justifyContent="center" alignItems="center" gap={4} mt={2}>
					<Stack direction="row" alignItems="center" justifyContent="space-between" width="70%" margin="0 auto" mb={2}>
						<Typography fontWeight={600} fontSize={26}>
							REMO.ai
						</Typography>

						<Stack direction="row" alignItems="center" gap={2}>
							{/* <Typography fontWeight={600}>CultureOS</Typography> */}
							<Link onClick={() => setTouchModal(true)}>Pricing</Link>
						</Stack>
					</Stack>

					<Typography maxWidth={small ? '90%' : '70%'} textAlign="center" fontWeight={600} fontSize={small ? 18 : 26}>
						Introducing world's unique AI agent, capable of understanding employee sentiments, through lifelike one-on-one
						conversation
					</Typography>

					<Stack direction="row" alignItems="center" gap={2}>
						<Button onClick={() => setTouchModal(true)} size="large" variant="contained">
							Get in Touch
						</Button>
						<Button onClick={() => window.open('https://app.cultureos.company/remo', '_blank')} size="large" variant="contained">
							Speak to Remo
						</Button>
					</Stack>

					<Stack mx="auto" width={small ? '80%' : '60%'} height={small ? 300 : 420}>
						<ReactPlayer width="100%" height="100%" url={VIDEO_LINK} />
					</Stack>
				</Stack>
			</Stack>

			<Stack mt={small ? 18 : 30} gap={4}>
				<Typography fontSize={26} fontWeight={600} textAlign="center">
					<span style={{ color: '#774F8F' }}>How</span> it out-performs?
				</Typography>

				<Stack maxWidth="85%" mx="auto" direction={small ? 'column' : 'row'} alignItems="center" gap={2}>
					<Stack width="100%">
						<img style={{ width: '100%' }} src={left} alt="left" />
					</Stack>
					{!small ? (
						<Stack>
							<img style={{ width: '100%' }} src={center} alt="center" />
						</Stack>
					) : (
						<Stack direction="row" alignItems="center" gap={2}>
							<Divider sx={{ width: 60 }} />
							<Typography fontWeight={600}>VS</Typography>
							<Divider sx={{ width: 60 }} />
						</Stack>
					)}
					<Stack width="100%">
						<img style={{ width: '100%' }} src={right} alt="right" />
					</Stack>
				</Stack>
			</Stack>

			<Stack mt={10} py={6} sx={{ background: 'linear-gradient(to bottom, #2F1A6E, #774F8F)' }} gap={10}>
				<Stack gap={3}>
					<Typography fontSize={28} textAlign="center" color="#fff" fontWeight={600}>
						Some sample conversations
					</Typography>

					<Grid sx={{ maxWidth: '80%', mx: 'auto' }} container spacing={2} rowGap={4}>
						{samples?.map((i) => (
							<Grid height={150} item xs={12} sm={6} lg={3}>
								<Stack height="100%" justifyContent="space-between" bgcolor="#fff" p={2} gap={2} borderRadius={3}>
									<Typography fontSize={18} fontWeight={600}>
										{i.title}
									</Typography>

									<ReactAudioPlayer style={{ width: '100%' }} src={i.audio} autoPlay={false} controls />
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>

				<Stack gap={3}>
					<Typography fontSize={28} textAlign="center" color="#fff" fontWeight={600}>
						Other uses-cases for employee conversations
					</Typography>

					<Stack
						mx="auto"
						direction="row"
						maxWidth={small ? '90%' : '60%'}
						flexWrap="wrap"
						alignItems="center"
						justifyContent="center"
						gap={2}
					>
						{others.map((i) => (
							<Stack bgcolor="#fff" borderRadius={50} p={2} fontSize={14}>
								{i.title}
							</Stack>
						))}
					</Stack>
				</Stack>
			</Stack>

			<Stack mt={10} gap={5} py={4}>
				<Typography fontSize={26} fontWeight={600} textAlign="center">
					<span style={{ color: '#774F8F' }}>Benefits</span> to Organization
				</Typography>

				<Stack maxWidth={small ? '80%' : '60%'} mx="auto">
					<img style={{ width: '100%' }} src={benifits} alt="left" />
				</Stack>
			</Stack>

			<BrandsCarousel />

			<Stack mt={6} py={6} sx={{ background: 'linear-gradient(to bottom, #2F1A6E, #774F8F)' }}>
				<Stack gap={2} alignItems="center">
					<Typography
						maxWidth={small ? '90%' : '70%'}
						textAlign="center"
						fontSize={small ? 20 : 34}
						mx="auto"
						color="#fff"
						fontWeight={600}
					>
						“Feedback and Communication are key drivers of employee well-being and Performance”
					</Typography>

					<img height={3} src={divider} alt="divider" />

					<Typography textAlign="center" fontSize={18} color="#fff" fontWeight={500}>
						University of Oxford 2020
					</Typography>

					<Button onClick={() => setTouchModal(true)} size="large" variant="contained">
						Get in Touch
					</Button>
				</Stack>

				<Stack mt={8} mb={2} height={120} justifyContent="center" bgcolor="#2F1A6E" width="100%">
					<Typography textAlign="center" fontSize={40} color="#fff" fontWeight={600}>
						REMO.ai
					</Typography>
				</Stack>

				<Typography textAlign="center" fontSize={14} color="#fff" fontWeight={500}>
					© 2024, Culture OS Inc. All Rights Reserved.
				</Typography>
			</Stack>
		</Stack>
	);
}
