import { Stack } from '@mui/material';
import React from 'react';

export default function BrandCard({ width = 190, height = 100, data, onClick, ariaHidden = false }) {
	return (
		<Stack
			width={width}
			height={height}
			flexShrink={0}
			p={2}
			bgcolor="#FFF"
			alignItems="center"
			justifyContent="center"
			borderRadius={2}
			boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
			aria-hidden={ariaHidden}
			sx={{
				transition: 'all 0.25s ease-in-out',
				cursor: 'pointer',
				':hover': { scale: '1.05' },
			}}
		>
			<img src={data?.logo} style={{ objectFit: 'contain', width: '90%', height: '80%' }} alt={data?.name} />
		</Stack>
	);
}
