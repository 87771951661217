import { createTheme } from '@mui/material';

export const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#8268EA',
			light: '#8268EA',
			dark: '#8268EA',
		},
		secondary: {
			main: '#fff',
		},
		grey: {
			100: '#DEE1E6',
			200: '#707683',
			300: '#90A0B7',
			400: '#b2b2b2',
		},
		black: {
			100: '#323C47',
			200: '#192A3E',
			700: '#000000',
		},
		blue: {
			50: '#EBF2FC',
			100: '#C2CFE099',
			125: '#DEEAFA',
			150: '#F4F4F6',
			700: '#1890FF',
			900: '#109CF1', // accent blue
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: 'Quicksand',
				},
				subtitle1: {},
				subtitle2: {},
				body1: {},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 80,
					boxShadow: 'none',
					fontSize: 12,
					fontWeight: 600,
					textTransform: 'none',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 15,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					cursor: 'pointer',
				},
			},
		},
	},
});
