import { Button, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from './TextInput';
import CountryCode from './CountryCode';
import axios from 'axios';

export default function GetInTouchModal({ onClose, setSuccessModal }) {
	const [data, setData] = useState({});
	const [countryCode, setCountryCode] = useState('+1');
	const [loading, setLoading] = useState(false);
	const handleInputChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		const user = { ...data, phone: countryCode + data.phone };
		let res = await axios.post(process.env.REACT_APP_URL + 'createcalluser', user);
		if (res.status === 200) {
			setLoading(false);
			setData({});
			onClose();
			setSuccessModal(true);
		}
	};

	const isDisabled = () => {
		if (!data.name || !data?.phone || !data?.email) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Stack maxWidth={400} p={2} gap={2}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography fontSize={20} fontWeight={600}>
					Get In Touch
				</Typography>
				<IconButton onClick={onClose} size="small">
					<CloseIcon sx={{ color: '#000', fontSize: 20 }} />
				</IconButton>
			</Stack>

			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<TextInput label="Name" value={data.name || ''} name="name" onChange={handleInputChange} />
				</Grid>
				<Grid item xs={12} md={12}>
					<TextInput
						prefix={<CountryCode countryCode={countryCode} setCountryCode={setCountryCode} />}
						type="number"
						label="Phone Number"
						value={data.phone || ''}
						name="phone"
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<TextInput label="Email ID" value={data.email || ''} name="email" onChange={handleInputChange} />
				</Grid>
				<Grid item xs={12} md={12}>
					<TextInput label="Message (Optional)" value={data.model || ''} multiline name="model" onChange={handleInputChange} />
				</Grid>

				<Grid item xs={12} md={12}>
					<Button disabled={isDisabled()} sx={{ height: 44 }} onClick={handleSubmit} fullWidth size="large" variant="contained">
						{loading ? <CircularProgress color="secondary" style={{ width: 20, height: 20 }} /> : 'Book'}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
