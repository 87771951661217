import { Stack, Typography } from '@mui/material';
import { Input } from 'antd';
import React from 'react';

const TextInput = (props) => {
	const { label, multiline } = props;
	return (
		<Stack position="relative">
			<Typography fontSize={14} fontWeight={500} color="#1E1E1E">
				{label}
			</Typography>
			{multiline ? (
				<Input.TextArea rows="4" style={{ backgroundColor: 'transparent', outline: 'none', color: '#1E1E1E' }} {...props} />
			) : (
				<Input style={{ backgroundColor: 'transparent', outline: 'none', color: '#1E1E1E', height: 44 }} {...props} />
			)}
		</Stack>
	);
};

export default TextInput;
