import { Stack, Typography } from '@mui/material';
import React from 'react';
import submit from '../../Assets/submit.svg';

export default function SuccessModal() {
	return (
		<Stack width={300} p={4} gap={2} alignItems="center" justifyContent="center">
			<img src={submit} alt="logo" />
			<Typography fontSize={18} fontWeight={600} color="#1E1E1E">
				Form Sumitted
			</Typography>
			<Typography textAlign="center" fontSize={18} fontWeight={500} color="#1E1E1E">
				Thanks for contacting us, our team will get back to you in 24 - 48 hrs{' '}
			</Typography>
		</Stack>
	);
}
