import { Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import BrandCard from './BrandCard';

export default function BrandsCarousel() {
	const small = useMediaQuery('(max-width:600px)');
	const list = [
		{
			name: 'Darwinbox',
			logo: require('../../Assets/brands/darwinbox.png'),
			link: 'https://api-docs.darwinbox.com/#29d9fe84-33e3-4b95-8eaf-91753eca0644',
		},
		{
			name: 'Workvivo',
			logo: require('../../Assets/brands/workvivo.png'),
			link: 'https://www.workvivo.com/integrations/',
		},
		{
			name: 'Lattice',
			logo: require('../../Assets/brands/lattice.png'),
			link: 'https://lattice.com/integrations',
		},
		{
			name: 'ADP',
			logo: require('../../Assets/brands/adp.png'),
			link: 'https://developers.adp.com/learn/key-concepts/introduction-to-adp-restful-apis#summary',
		},
		{
			name: 'Leapsome',
			logo: require('../../Assets/brands/leapsome.webp'),
			link: 'https://api.leapsome.com/v1/api-docs/',
		},
		{
			name: 'Advantage Club',
			logo: require('../../Assets/brands/advantageclub.png'),
			link: 'https://www.advantageclub.ai/',
		},
		{
			name: 'SuccessFactor',
			logo: require('../../Assets/brands/successfactor.png'),
			link: 'https://api.leapsome.com/v1/api-docs/',
		},
		{
			name: 'Workday',
			logo: require('../../Assets/brands/workday.png'),
			link: 'https://apidocs.workdayspend.com/services/attachments/v1.html',
		},
	];

	return (
		<Stack gap={3} mt={5}>
			<Typography maxWidth="80%" mx="auto" fontSize={small ? 20 : 26} fontWeight={600} textAlign="center">
				Seamlessly <span style={{ color: '#774F8F' }}>one-click integration</span> with any of <br /> the existing systems
				mentioned below
			</Typography>
			<Stack
				sx={{
					width: '100%',
					'& .scroller': {
						overflow: 'hidden',
						mask: 'linear-gradient(90deg, transparent, #000000 10%, #000000 90%, transparent)',
						':hover': {
							overflow: 'auto',
							'::-webkit-scrollbar': {
								display: 'none',
							},
						},
					},
					'& .scroller_inner': {
						width: 'max-content',
						flexWrap: 'nowrap',
						p: 1,
						animation: 'scroll 30s forwards linear infinite',
						':hover': {
							animationPlayState: 'paused',
						},
					},
					'@keyframes scroll': {
						to: {
							transform: 'translate(calc(-50%))',
						},
					},
				}}
			>
				<Stack className="scroller">
					<Stack className="scroller_inner" direction="row" alignItems="center" gap={4}>
						{list?.map((item) => (
							<BrandCard data={item} />
						))}

						{/* duplicated for auto-scroll animation */}
						{list?.map((item) => (
							<BrandCard data={item} />
						))}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}
